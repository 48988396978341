export const albums_data = {
   "eclipse-2024": {
      "name": "Eclipse 2024",
      "photos": [
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_135329.jpg",
            "slug": "20240408-135329-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-135329-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-135329-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-135329-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-135329-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-135329-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-135329-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_140239.jpg",
            "slug": "20240408-140239-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-140239-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-140239-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-140239-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-140239-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-140239-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-140239-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_141706.jpg",
            "slug": "20240408-141706-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-141706-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-141706-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-141706-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-141706-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-141706-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-141706-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_144017.jpg",
            "slug": "20240408-144017-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-144017-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-144017-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-144017-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-144017-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-144017-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-144017-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_145947.jpg",
            "slug": "20240408-145947-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-145947-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-145947-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-145947-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-145947-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-145947-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-145947-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_150630.jpg",
            "slug": "20240408-150630-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-150630-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-150630-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-150630-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-150630-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-150630-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-150630-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_151008.jpg",
            "slug": "20240408-151008-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151008-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-151008-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151008-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-151008-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-151008-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-151008-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_151235.jpg",
            "slug": "20240408-151235-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151235-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-151235-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151235-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-151235-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-151235-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-151235-jpg.jpg",
            "width": 4000
         },
         {
            "faces": [],
            "height": 3000,
            "name": "20240408_151344.jpg",
            "slug": "20240408-151344-jpg",
            "src": "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151344-jpg.jpg",
            "srcSet": {
               "(1024, 1024)w": [
                  "/static/_gallery/albums/eclipse-2024/1024x768_20240408-151344-jpg.jpg"
               ],
               "(1600, 1600)w": [
                  "/static/_gallery/albums/eclipse-2024/1600x1200_20240408-151344-jpg.jpg"
               ],
               "(500, 500)w": [
                  "/static/_gallery/albums/eclipse-2024/500x375_20240408-151344-jpg.jpg"
               ],
               "(800, 800)w": [
                  "/static/_gallery/albums/eclipse-2024/800x600_20240408-151344-jpg.jpg"
               ]
            },
            "thumb": "/static/_gallery/albums/eclipse-2024/500x375_20240408-151344-jpg.jpg",
            "width": 4000
         }
      ],
      "slug": "eclipse-2024",
      "src": "/static/_gallery/albums/eclipse-2024/500x375_20240408-135329-jpg.jpg"
   }
};